<div class="banner-wrapperer">
  <div class="banner-content">
    <div class="banner-content-title">
      <img class='locker' src='/assets/images/icons/locker.svg'>
      <h2>Вы видите только часть этого материала</h2>
    </div>
    <p>
      Этот материал был написан специально для рассылки The Bell. Это платный продукт. Чтобы прочитать статью целиком и иметь доступ ко всему контенту The Bell, подпишитесь на рассылку.
    </p>
<!--    <p>-->
<!--      Сейчас действует новогодняя скидка на рассылки The Bell. Купите подписку себе или в подарок другу — с праздничной скидкой 17%!-->
<!--    </p>-->
    <button id='mailings_subscribe_button' (click)="setSuccessLinkAndNavigate('/paywall')">
      <div class="d-flex">
        Подписаться
        <!-- Новогодняя иконка, пусть будет, пригодится на след год
        <img width="17" height="20" src="/assets/images/icons/new_year.svg" class="new-year-icon" alt="new-year-icon">
        -->
      </div>
    </button>
    <button id='mailings_login_button' *ngIf="!isUserAuthorized" class="accent" (click)="setSuccessLinkAndNavigate('/account')">Я уже подписчик</button>
    <p class="banner-gift-subscription">
      Вы также можете подарить эту подписку
    </p>
  </div>
</div>

import {
  APPLY_DISCOUNT_FOR_PAID_SUBSCRIPTION,
  CREATE_SETUP_INTENT_FOR_STRIPE,
  USER_SUBSCRIPTIONS,
  WRITE_SUBSCRIPTION_RETENTION_HISTORY,
} from '../contracts/api/subscription';
import { GET_SUBSCRIPTION_PLANS_BY_PRODUCT } from '../contracts/api/subscription-plan';
import { WriteRetentionHistoryParams } from '../contracts/params/subscription';
import {
  AllowedProductStatus,
  CreateSetupIntentDto,
  CreateSetupIntentInputDto,
  GqlDto,
  SubscriptionDto,
  SubscriptionPlanDto
} from '@thebell/data-transfer-objects';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { FetchPolicy } from '@apollo/client';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable()
export class SubscriptionService {
  constructor(private apollo: Apollo) {}

  async fetchUserSubscriptions(email: string) {
    // todo realise on back side
    const observables: Observable<SubscriptionDto>[] = [];

    [AllowedProductStatus.BELL_PRO, AllowedProductStatus.INVEST].forEach((productType: string) => {
      const query = this.apollo
        .query<GqlDto<'subscriptionsByAuth', SubscriptionDto>>({
          query: USER_SUBSCRIPTIONS, variables: {email, productType}, context: {}
        })
        .pipe(map((res) => res.data.subscriptionsByAuth));
      observables.push(query);
    });

    const subs = await forkJoin(observables).toPromise();
    return subs.filter((sub) => !!sub);
  }

  createSetupIntent(
    input: CreateSetupIntentInputDto
  ) {
    return this.apollo
      .mutate<GqlDto<'createSetupIntent', CreateSetupIntentDto>>({
        mutation: CREATE_SETUP_INTENT_FOR_STRIPE,
        variables: {input},
      })
      .pipe(map((res) => res.data.createSetupIntent))
  }

  /**
   * @param product
   * @param promoCode
   * @param subscriptionId use only for one case
   * — get plans with RETENTION couponCode
   * @param fetchPolicy
   */
  async fetchSubscriptionPlansByProduct(
    product: AllowedProductStatus,
    promoCode?: string,
    subscriptionId?: number,
    fetchPolicy: FetchPolicy = null
  ) {
    return this.apollo
      .query<GqlDto<'getSubscriptionPlansByProduct', SubscriptionPlanDto[]>>({
        query: GET_SUBSCRIPTION_PLANS_BY_PRODUCT,
        variables: {
          product: product.toUpperCase(),
          promoCode,
          subscriptionId,
        },
        fetchPolicy
      })
      .pipe(map((res) => res.data.getSubscriptionPlansByProduct))
      .toPromise();
  }

  applyCoupon(
    subscriptionId: number,
    couponCode: string,
    newPlanId: number,
    userAccessKey: string
  ): Promise<SubscriptionDto> {
    return this.apollo
      .mutate<GqlDto<'applyDiscountForPaidSubscription', SubscriptionDto>>({
        mutation: APPLY_DISCOUNT_FOR_PAID_SUBSCRIPTION,
        variables: {key: userAccessKey, id: subscriptionId, couponCode, newPlanId},
        context: {
          headers: {
            access_key: userAccessKey,
          },
        },
      })
      .pipe(map((res) => res.data.applyDiscountForPaidSubscription))
      .toPromise();
  }

  writeRetentionHistory({
    email,
    subscriptionId,
    userChoice,
    userFeedback,
    comment,
  }: WriteRetentionHistoryParams): Promise<boolean> {
    return this.apollo
      .mutate<GqlDto<'writeSubscriptionRetentionHistory', boolean>>({
        mutation: WRITE_SUBSCRIPTION_RETENTION_HISTORY,
        variables: {
          email,
          historyItem: {
            subscriptionId,
            userChoice,
            userFeedback,
            comment,
          },
        },
      })
      .pipe(map((res) => res.data.writeSubscriptionRetentionHistory))
      .toPromise();
  }
}

/** Determines the rules for display popup */
export interface DisplayStrategyInterface {
  /**
   * Check static conditions to process popup, call before {@link trigger}
   * @returns {Promise<boolean>} - Resolves <b>true</b> to process, <b>false</b> to skip
   */
  canShown(): Promise<boolean>;

  /**
   * Checks dynamic conditions to show the popup.
   * @returns {Promise<boolean>} - Resolve <b>true</b> to show, <b>false</b> to skip.
   */
  trigger(): Promise<boolean>;
  displayPolicy: DisplayPolicyType;
  interactPolicy: InteractPolicyType;
}

/**
 * - Waiting: wait for current popup to hidden before display the next
 * - Overlay: display the next popup on top
 * - Replace: close current popup before display the next
 */
export enum InteractPolicyType {
  Waiting,
  Overlay,
  Replace
}

/**
 * - Always: displayed regardless of queue order
 * - FirstOnly: Displayed if first or if prior items are skipped
 */
export enum DisplayPolicyType {
  Always,
  FirstOnly
}

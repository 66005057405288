<div class="row">
  <div class="col logo">
    <a routerLink="/">
      <img [src]='"assets/images/logo.svg"' alt="The Bell logo" width="146" height="33">
    </a>
  </div>

  <div class="col menu" [@openCloseTags]="searchIsOpenState">
    <div class="tags">
      <a
        routerLink=""
        routerLinkActive="select"
        [routerLinkActiveOptions]="{exact: true}"
        (click)="onColMenuItemClick('main')"
        [ngClass]="{ select: isMenuItemActive('main') }"
        >Главное</a
      >
      <a routerLink="/category/news" routerLinkActive="select" [ngClass]="{ select: isMenuItemActive('news') }" (click)="onColMenuItemClick('news')">Новости</a>
      <a routerLink="/tag/ekonomika" routerLinkActive="select" [ngClass]="{ select: isMenuItemActive('ekonomika') }" (click)="onColMenuItemClick('ekonomika')">Экономика</a>
      <a routerLink="/tag/finansy" routerLinkActive="select" [ngClass]="{ select: isMenuItemActive('finansy') }" (click)="onColMenuItemClick('finansy')">Финансы</a>
      <a routerLink="/tag/tehnologii" routerLinkActive="select" [ngClass]="{ select: isMenuItemActive('internet') }" (click)="onColMenuItemClick('internet')">Технологии</a>
      <a routerLink="/tag/biznes" routerLinkActive="select" [ngClass]="{ select: isMenuItemActive('biznes') }" (click)="onColMenuItemClick('biznes')">Бизнес</a>
      <a routerLink="/category/bell-investitsii" routerLinkActive="select" [ngClass]="{ select: isMenuItemActive('bell-investitsii') }" (click)="onColMenuItemClick('bell-investitsii')">Инвестиции</a>

    </div>
  </div>



  <div class="col addition_links" style="display: none">
    <app-bellclub-link (click)="onColMenuItemClick('bellclub')"></app-bellclub-link>
    <app-in-school (click)="onColMenuItemClick('in_school')"></app-in-school>
    <!-- <app-business-for-life (click)="onColMenuItemClick('business_for_life')"></app-business-for-life> -->
    <!-- <app-pmef-button (click)="onColMenuItemClick('pmef')"></app-pmef-button> -->
  </div>

  <div class="col tools">

    <div class="col donate">
      <a class="button" target="_blank" href="/paywall?utm_source=thebell.io&utm_medium=static_button&utm_campaign=regular">Подписаться
        <!-- Новогодняя иконка, пусть будет, пригодится на след год
        <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <mask id="mask0_2269_12923" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="3" width="17" height="18">
            <circle cx="8.50008" cy="11.7135" r="8.28767" fill="black"/>
          </mask>
          <g mask="url(#mask0_2269_12923)">
            <circle cx="8.50008" cy="11.7135" r="8.28767" fill="white"/>
            <path d="M0.524414 8.13477H16.7107" stroke="#EB3323" stroke-width="1.2451"/>
            <path d="M-0.488281 10.5859H17.4878" stroke="#EB3323" stroke-width="1.2451"/>
            <path d="M0.406738 15.9563L3.2082 13.7773L5.62058 16.2675L8.46095 13.7773L11.3402 16.2675L13.7137 13.7773L16.7486 16.2675" stroke="#EB3323" stroke-width="1.2451"/>
          </g>
          <rect x="6.59374" y="0.622548" width="3.73529" height="5.52512" rx="1.86764" stroke="white" stroke-width="1.2451"/>
        </svg>
        -->
      </a>
    </div>

  <div class="col search" [@openCloseSearch]="searchIsOpenState">
    <input #searchInput (keyup)="keyup($event)" [(ngModel)]="searchValue" />
    <app-button
      [buttonClass]="'default search'"
      [spanClass]="'default-text search-text'"
      [buttonText]="'Найти'"
      (buttonClick)="search()"
      [disabled]="checkSearchIsEmpty()"
    ></app-button>
  </div>

  <a class="lang" href="https://en.thebell.io" [attr.aria-label]="'The Bell English'">
    <span>en</span>
  </a>

  <div class="col pointer search-icon">
    <div (click)="changeOpenSearchState()" class="search-container">
      <app-image
        *ngIf="!searchIsOpen"
        [imageClass]=""
        [image]="'/assets/images/search.svg'"
        [imageType]="'image'"
        width=21
        height=22
        [isResizable]="false"
      ></app-image>
    </div>
    <div>
      <app-image
        *ngIf="searchIsOpen"
        [imageClass]=""
        [image]="'/assets/images/cross.svg'"
        [imageType]="'image'"
        [isResizable]="false"
        width='21'
        height='21'
        (click)="changeOpenSearchState()"
      ></app-image>
    </div>
  </div>

  <div class="col pointer burger" (click)="$event.stopPropagation(); changeOpenMenuState()">
    <div>
      <app-image
        [imageClass]="'menu'"
        [image]="'/assets/images/menuline.svg'"
        [imageType]="'image'"
        [isResizable]="false"
        width='20'
        height='2'
      ></app-image>
      <div [@menuAnimation]="menuIsOpen">
        <app-image
          [imageClass]="'menu'"
          [image]="'/assets/images/menuline.svg'"
          [imageType]="'image'"
          width='20'
          height='2'
          [isResizable]="false"
        ></app-image>
      </div>
    </div>
  </div>

  <div class="col pointer account">
    <a *ngIf="!isUserAuthorized; else notAuth" href="/account" class="login-link" [attr.aria-label]="'Account login'">
      Войти
    </a>
    <ng-template #notAuth>
      <a href="/account" class="logo__account__icon"><p>{{ getFirstLetter(email) }}</p></a>
    </ng-template>

  </div>
</div>

  <div class="col pointer account-mobile">
    <a href="/account">
    <img src="/assets/icons/user.svg" alt="Lock Icon" class="lock-icon" width="21" height="20">
    </a>
  </div>
  <div class="col pointer burger-mobile" (click)="$event.stopPropagation(); changeOpenMenuState()">
    <div>
      <app-image
        [imageClass]="'menu'"
        [image]="'/assets/images/menuline.svg'"
        [imageType]="'image'"
        [isResizable]="false"
        width='20'
        height='2'
      ></app-image>
      <div [@menuAnimation]="menuIsOpen">
        <app-image
          [imageClass]="'menu'"
          [image]="'/assets/images/menuline.svg'"
          [imageType]="'image'"
          width='20'
          height='2'
          [isResizable]="false"
        ></app-image>
      </div>
    </div>
  </div>
</div>

<!-- Дополнительная кнопка для мобильной версии -->
<div class="row_button_mobile">
  <a class="button_mobile" target="_blank" href="/paywall?utm_source=thebell.io&utm_medium=static_button&utm_campaign=regular">Подписаться
    <!-- Новогодняя иконка, пусть будет, пригодится на след год
    <img width="17" height="20" src="/assets/images/icons/new_year_revert.svg" alt="new-year-icon">
    -->
  </a>
</div>

  <!--
  <div class="row_button">
    <app-pmef-button (click)="onColMenuItemClick('pmef')"></app-pmef-button>
  </div>
  -->
<div [@openCloseMenu]="menuIsOpen">
  <app-menu [position]="'header'" (linkClick)="changeOpenMenuState(false)">
    <a class="lang mobile" href="https://en.thebell.io" [attr.aria-label]="'The Bell English'">
      <span>en</span>
    </a></app-menu>

</div>


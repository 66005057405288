import {mergeDeep} from '@apollo/client/utilities';
import * as Plugin from 'vanilla-cookieconsent';
import {Component, Input, ViewEncapsulation, Renderer2, OnInit} from '@angular/core';
import {consentWidgetConfig} from './consent-widget.config';
import {ManagerService} from '../manager';
import {Router} from '@angular/router';
import {Category as ScriptCategory} from '../contracts/types';
import {
  DisplayPolicyType,
  DisplayStrategyInterface,
  InteractPolicyType,
  PopupControllerInterface,
  PopupState
} from '@thebell/frontend/services/popup-queue-manager';
import {BehaviorSubject, Observable} from 'rxjs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'privacy-consent-widget',
  templateUrl: './consent-widget.component.html',
  styleUrls: ['./consent-widget.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ConsentWidgetComponent implements PopupControllerInterface, OnInit {
  public static readonly defaultConfig = consentWidgetConfig;

  readonly uid = 'privacy-consent';
  readonly displayStrategy: DisplayStrategyInterface;
  readonly state$: Observable<PopupState>;

  private readonly config: any | Plugin.CookieConsentConfig;
  private readonly stateSubject: BehaviorSubject<PopupState>;

  @Input() theme = 'default';

  constructor(
    scriptManager: ManagerService,
    private renderer: Renderer2,
    private router: Router
  ) {
    this.config = scriptManager.config.consentWidget;

    this.stateSubject = new BehaviorSubject<PopupState>(PopupState.NotDisplayed);
    this.state$ = this.stateSubject.asObservable();

    this.displayStrategy = {
      displayPolicy: DisplayPolicyType.Always,
      interactPolicy: InteractPolicyType.Waiting,
      async trigger(): Promise<boolean> {
        return true;
      },
      async canShown(): Promise<boolean> {
        return !Plugin.validConsent();
      }
    }
  }

  get state(): PopupState {
    return this.stateSubject.getValue();
  }

  show(params?: any) {
    //todo set cc-z-index var
    Plugin.show(true);
  }

  hide() {
    Plugin.hide();
  }

  ngOnInit() {
    const config = mergeDeep(ConsentWidgetComponent.defaultConfig, this.config);

    config.autoShow = false; // this is important!
    config.root = '#privacy-consent-widget';
    config.onModalReady = ({modal}) => {
      const modalBody = modal.querySelector('.cm__body');
      if (modalBody) {
        const cross = document.createElement('div');
        cross.classList.add('cm__cross');
        cross.innerHTML =
          '<svg viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">' +
          '<g>' +
          '<rect x="1.30122" y="2.00867" width="1" height="25.0319" rx="0.5" transform="rotate(-45 1.30122 2.00867)"/>' +
          '<rect x="-0.707107" width="1" height="25.0319" rx="0.5" transform="matrix(-0.707107 -0.707107 -0.707107 0.707107 19.2082 1.50867)"/>' +
          '</g>' +
          '</svg>';
        modalBody.append(cross);

        this.renderer.listen(cross, 'click', () => {
          // Сохранить настройки чтобы больше не отображать виджет
          Plugin.acceptCategory(ScriptCategory.Necessaries);
          this.hide();
        });
      }

      const links = modal.querySelectorAll('a[href]');
      links.forEach((link: HTMLLinkElement) => {
        this.renderer.listen(link, 'click', (event) => {
          event.preventDefault();
          Plugin.hidePreferences();
          this.router.navigateByUrl(link.getAttribute('href'));
        });
      });
    };

    config.onModalShow = ({modalName}) => {
      if (modalName === 'consentModal') {
        this.stateSubject.next(PopupState.Displayed);
      }
    };

    config.onModalHide = ({modalName}) => {
      if (modalName === 'consentModal') {
        this.stateSubject.next(PopupState.Hidden);
      }
    };

    Plugin.run(config);
  }
}

import { SubscriptionPlanViewModel } from './subscription-plan.view-model';
import {
  AllowedDateType,
  Currency,
  PaymentSystem,
  SubscriptionDto,
  SubscriptionPlanDto,
  SubscriptionPriceChangeDto
} from '@thebell/data-transfer-objects';

/**
 * @final
 */
export class SubscriptionViewModel extends SubscriptionPlanViewModel {
  constructor(
    protected readonly subscription: SubscriptionDto
  ) {
    super(subscription.subscriptionPlan as SubscriptionPlanDto, subscription.ccsp);
  }

  get price(): number {
    if (this.isSupportedPaymentSystem()) {
      return super.price;
    }

    return this.currency === Currency.USD
      ? this.subscription.amount / 100
      : this.subscription.amount;
  }

  get hasDiscount(): boolean {
    if (!this.isSupportedPaymentSystem() || !super.hasDiscount) {
      return false;
    }

    if (this.hasPromoPeriod) {
      const usedCouponPeriod = this.mathDateDiff(
        new Date(),
        this.getApplyCouponDate(),
        this.ccsp.promoPeriodType as unknown as AllowedDateType
      );

      return usedCouponPeriod < this.ccsp.promoPeriodValue;
    }

    return super.hasDiscount;
  }

  private isSupportedPaymentSystem() {
    return ([PaymentSystem.STRIPE, PaymentSystem.CLOUDPAYMENTS] as string[])
      .includes(this.plan.paymentSystemId.toLowerCase());
  }

  private getApplyCouponDate(): Date {
    // Если есть изменения тарифного плана, то находим последнее
    // в котором был другой купон (или купон отсутствовал). Это и будет изменение,
    // которым применили текущий купон. В противном случае считаем от даты подписки
    const couponPriceChanges = (this.subscription.changes as SubscriptionPriceChangeDto[])
      .filter((change) => change && change.oldCcspId !== this.subscription.ccspId);

    const couponPriceChange = couponPriceChanges.reduce(
      (latest, current) => !latest || current.createdAt > latest.createdAt ? current : latest,
      null
    );

    return couponPriceChange?.endDate
      ? new Date(couponPriceChange.endDate)
      : new Date(this.subscription.startedAt);
  }

  private mathDateDiff(
    startDate: Date,
    endDate: Date,
    periodType: AllowedDateType
  ): number {
    const startPeriod = new Date(Math.min(startDate.getTime(), endDate.getTime()));
    const endPeriod = new Date(Math.max(startDate.getTime(), endDate.getTime()));

    const msInDay = 24 * 60 * 60 * 1000;
    const timeDiff = Math.abs(startPeriod.getTime() - endPeriod.getTime());

    switch (periodType.toUpperCase()) {
      case AllowedDateType.DAY.toUpperCase():
        return Math.floor(timeDiff / msInDay);

      case AllowedDateType.WEEK.toUpperCase():
        return Math.floor(timeDiff / (msInDay * 7));

      case AllowedDateType.MONTH.toUpperCase(): {
        let monthsDiff = (endPeriod.getFullYear() - startPeriod.getFullYear()) * 12;
        monthsDiff += endPeriod.getMonth() - startPeriod.getMonth();

        if (endPeriod.getDate() < startPeriod.getDate()) {
          monthsDiff--;
        }

        return monthsDiff;
      }

      case AllowedDateType.YEAR.toUpperCase(): {
        let yearsDiff = endPeriod.getFullYear() - startPeriod.getFullYear();
        if (
          endPeriod.getMonth() < startPeriod.getMonth() ||
          (endPeriod.getMonth() === startPeriod.getMonth() && endPeriod.getDate() < startPeriod.getDate())
        ) {
          yearsDiff--;
        }

        return yearsDiff;
      }

      default:
        throw new Error(`Unexpected period type: ${periodType}`);
    }
  }
}

import { Observable } from 'rxjs';
import { DisplayStrategyInterface } from './display-strategy.interface';

export interface PopupControllerInterface {
  uid: string;
  displayStrategy: DisplayStrategyInterface;
  readonly state: PopupState;
  state$: Observable<PopupState>;
  show(params?: any): void;
  hide(): void;
}

/**
 * - NotDisplayed: initial state, has not been shown
 */
export enum PopupState {
  NotDisplayed,
  Displayed,
  Hidden
}

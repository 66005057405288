import { gql } from 'apollo-angular';

export const GET_SUBSCRIPTION_PLANS_BY_PRODUCT = gql`
  query getSubscriptionPlansByProduct($product: AllowedProductStatus!, $promoCode: String, $subscriptionId: Int) {
    getSubscriptionPlansByProduct(product: {product: $product}, promoCode: $promoCode, subscriptionId: $subscriptionId) {
      id
      name
      description
      status
      periodValue
      periodType
      price
      createdAt
      updatedAt
      listId
      position
      slug
      currency
      paymentSystemId
      customName
      product
      couponCodeSubscriptionPlans {
        subscriptionPlanId
        couponCodeId
        couponCode {
          code
          status
        }
        applyCouponCode
        discountPercent
        isDiscounted
        promoPeriodValue
        promoPeriodType
        isOnlyForNew
        isRepetable
        isGiftable
        showOriginalPrice
        newPrice
      }
    }
  }
`;

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ClipboardModule} from '@angular/cdk/clipboard';
import {CommonUiUtilsModule} from '@thebell/common/ui/utils';
import {AdvertisementComponent} from './advertisement';
import {BellClubComponent} from './bell-club';
import {EmptyComponent} from './empty';
import {LightningModule} from './lightning';
import {NewsModule} from './news';
import {NumberOfDayModule} from './number-of-day';
import {PostComponent, PostDonateBannerDirective, PostWebpImagesPipe} from './post';
import {QuoteComponent} from './quote';
import {SubscriptionComponent} from './subscription';
import {CollapsePostComponent} from './collapse-post';
import {DirectivesModule} from '@thebell/common/directives/client';
import {CommonUiUiUtilsModule} from '@thebell/common/ui/ui-utils';
import {MatIconModule} from '@angular/material/icon';
import {PostDonateBannerPipe} from './post/post-donate-banner.pipe';
import {PaywallComponent} from './paywall-banner';
import {PaywallModalComponent} from './paywall-banner-modal/paywall-modal.component';
import {InoagentComponent} from './inoagent-banner/inoagent.component';
import {AdThetruestoryComponent} from './ad-sidebar';
//import {RecaptchaCommonModule} from "ng-recaptcha/lib/recaptcha-common.module";

import {
  RECAPTCHA_LANGUAGE,
  RECAPTCHA_SETTINGS,
  RECAPTCHA_V3_SITE_KEY,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from 'ng-recaptcha';
import {MailingsPaywallComponent} from './mailings-paywall-banner';
import {DecemberBannerComponent, DecemberStickyGiftComponent} from './december-promote';

@NgModule({
  declarations: [
    AdvertisementComponent,
    BellClubComponent,
    EmptyComponent,
    PostComponent,
    PaywallComponent,
    MailingsPaywallComponent,
    PaywallModalComponent,
    InoagentComponent,
    QuoteComponent,
    SubscriptionComponent,
    CollapsePostComponent,
    PostDonateBannerPipe,
    PostDonateBannerDirective,
    PostWebpImagesPipe,
    AdThetruestoryComponent,
    DecemberBannerComponent,
    DecemberStickyGiftComponent,
  ],
    imports: [
        CommonModule,
        CommonUiUtilsModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        ClipboardModule,
        DirectivesModule,
        CommonUiUiUtilsModule,
        MatIconModule,
        //RecaptchaCommonModule,
      RecaptchaModule,
      RecaptchaFormsModule,
    ],
  exports: [
    AdvertisementComponent,
    BellClubComponent,
    EmptyComponent,
    LightningModule,
    NewsModule,
    NumberOfDayModule,
    PostComponent,
    QuoteComponent,
    SubscriptionComponent,
    CollapsePostComponent,
    AdThetruestoryComponent,
    PostDonateBannerDirective,
    InoagentComponent,
    DecemberBannerComponent,
    DecemberStickyGiftComponent,
  ],
  providers: [
    {
      provide: RECAPTCHA_LANGUAGE,
      useValue: 'ru',
    }
  ],
})
export class CommonUiWidgetsModule {}

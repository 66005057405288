import {
  AllowedDateType,
  CouponCodeDto,
  CouponCodeSubscriptionPlanDto,
  Currency,
  PaymentSystem,
  SubscriptionPlanDto
} from '@thebell/data-transfer-objects';
import { pluralizeRu, pluralizeRuDate } from '@thebell/common/utils';

export class SubscriptionPlanViewModel {
  constructor(
    protected readonly plan: SubscriptionPlanDto,
    protected readonly ccsp?: CouponCodeSubscriptionPlanDto
  ) {}

  get planId(): number {
    return this.plan.id;
  }

  get title(): string {
    return this.plan.customName || this.periodLabel;
  }

  get description(): string {
    return this.plan.description;
  }

  get periodValue(): number {
    return this.plan.periodValue;
  }

  get periodType(): AllowedDateType {
    return this.plan.periodType.toLowerCase() as AllowedDateType;
  }

  get couponCode(): null | string {
    return (this.ccsp?.couponCode as unknown as CouponCodeDto)?.code || null
  }

  get hasDiscount(): boolean {
    return this.ccsp?.isDiscounted;
  }

  get discountPercent(): number {
    return this.ccsp.discountPercent || 0;
  }

  get hasPromoPeriod(): boolean {
    return this.ccsp?.promoPeriodValue > 0;
  }

  get price(): number {
    return this.hasDiscount ? this.ccsp.newPrice : this.plan.price;
  }

  get regularPrice(): number {
    return this.plan.price;
  }

  get currency(): Currency {
    return this.plan.currency;
  }

  /**
   * @return payment system name with currency symbol like "PayPal ($)" or "RUB (₽)"
   */
  get paymentSystemAlias(): string {
    const aliases = {
      [PaymentSystem.BOOSTY]: 'Boosty',
      [PaymentSystem.PAYPAL]: 'PayPal',
      [PaymentSystem.BRAINTREE]: 'BrainTree'
    };

    return (aliases[this.plan.paymentSystemId.toLowerCase()] || this.plan.currency.toUpperCase())
      + ` (${this.currencySymbol})`;
  }

  get currencySymbol(): null | string {
    const symbols = {
      [Currency.USD]: '$',
      [Currency.RUB]: '₽'
    };

    return symbols[this.plan.currency] || null;
  }

  get periodLabel(): null | string {
    const labels = {
      [`${AllowedDateType.MONTH}_1`]: 'Ежемесячный',
      [`${AllowedDateType.MONTH}_6`]: 'Полугодовой',
      [`${AllowedDateType.MONTH}_12`]: 'Годовой',
      [`${AllowedDateType.YEAR}_1`]: 'Годовой'
    };

    return labels[`${this.plan.periodType.toLowerCase()}_${this.plan.periodValue}`] || null;
  }

  getPricePerMonth(): number {
    return this.price / this.getPeriodIn(AllowedDateType.MONTH);
  }

  getRegularPricePerMonth(): number {
    return this.regularPrice / this.getPeriodIn(AllowedDateType.MONTH);
  }

  getPeriodEnding(): null | string {
    const endings = {
      [`${AllowedDateType.MONTH}_1`]: '/мес.',
      [`${AllowedDateType.MONTH}_6`]: '/полгода',
      [`${AllowedDateType.MONTH}_12`]: '/год',
      [`${AllowedDateType.YEAR}_1`]: '/год'
    };

    return endings[`${this.plan.periodType.toLowerCase()}_${this.plan.periodValue}`] || null;
  }

  getPluralPeriod(withFirstPrefix = false, shortPeriodName = false): string {
    if (this.periodValue === 6 && this.periodType === AllowedDateType.MONTH) {
      return withFirstPrefix ? 'первые полгода' : 'полгода';
    }
    if (this.periodValue === 12 && this.periodType === AllowedDateType.MONTH) {
      return withFirstPrefix ? 'первый год' : 'год';
    }

    let result = '';
    if (withFirstPrefix) {
      result += `${pluralizeRu(this.periodValue, 'первый', 'первые', 'первых')} `;
    }
    if (this.periodValue > 1) {
      result += `${this.periodValue} `;
    }

    result += ` ${pluralizeRuDate(this.periodValue, this.periodType, shortPeriodName)}`;
    return result.trim();
  }

  getPeriodInMonths(): number {
    return this.getPeriodIn(AllowedDateType.MONTH);
  }

  getPeriodIn(periodType: AllowedDateType): number
  {
    if (periodType === AllowedDateType.MONTH) {
      switch (this.plan.periodType.toLowerCase()) {
        case AllowedDateType.MONTH:
          return this.plan.periodValue;
        case AllowedDateType.YEAR:
          return this.plan.periodValue * 12;
      }
    } else {
      throw new Error('Not supported periodType, need implementation');
    }

    throw new Error(
      'Unexpected periodType for subscription in view model: '
      + `${this.plan.periodType.toLowerCase()}. Must be 'month' or 'year'`
    );
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {User} from '@thebell/common/models/user';
import {BaseModel} from '@thebell/common/models/base-model';
import {Environment, EnvironmentService} from '@thebell/common/services/core/environment';
import {ILaravelCredentials} from '@thebell/common/models/credentials';
import {Clients} from "../../../../../models/clients/src";
import {CookieService} from 'ngx-cookie-service';
import {PRO_TOKEN_NAME, TOKEN_NAME} from '../../../../../../../apps/client/src/server/utils/key-transformers.utils';


@Injectable({
  providedIn: 'root',
})
export class ClientAuthService {
  static logoutTimeout: number;
  static logoutObs = new Subject<'logout'>();
  private static tokenPrefix: string;
  public $error$: Subject<string> = new Subject<string>();
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  private environment: Environment;
  private authUrl: string;
  private tokenPrefix: string;
  private static cookieService: CookieService;

  constructor(private envService: EnvironmentService, private http: HttpClient, private cookieService: CookieService) {
    this.environment = this.envService.getEnvironment();
    this.authUrl = `${this.environment.baseUrl}oauth/token`;
    this.tokenPrefix = 'client_auth_';
    ClientAuthService.cookieService = cookieService;
  }

  get laravelToken(): string {
    const expDate = new Date(localStorage.getItem(`${this.tokenPrefix}token-expired`));
    if (new Date() > expDate) {
      ClientAuthService.logout();
      return null;
    }
    return localStorage.getItem(`${this.tokenPrefix}laravel_access_token`);
  }

  get nestToken(): string {
    const expDate = new Date(localStorage.getItem(`${this.tokenPrefix}token-expired`));
    if (new Date() > expDate) {
      ClientAuthService.logout();
      return null;
    }
    return localStorage.getItem(`${this.tokenPrefix}nest_access_token`);
  }

  get user() {
    return localStorage.getItem(`${this.tokenPrefix}currentUser`);
  }

  static logoutTimer(expires_in: number | null): Subject<'logout'> {
    if (expires_in) {
      ClientAuthService.logoutTimeout = window.setTimeout(() => {
        ClientAuthService.logoutObs.next('logout');
      }, expires_in);
    } else {
      clearTimeout(ClientAuthService.logoutTimeout);
    }
    return ClientAuthService.logoutObs;
  }

  static setToken(response: {
    laravel_access_token: string; nest_access_token: string; expires_in: number; ad_key: string; pro_key: string;
  } | null) {
    this.tokenPrefix = 'client_auth_'
    if (response) {
      //const expDate = new Date(new Date().getTime() + response.expires_in * 1000);
      const expDate = new Date(new Date().getTime() + response.expires_in);
      localStorage.setItem(`${this.tokenPrefix}laravel_access_token`, response.laravel_access_token);
      localStorage.setItem(`${this.tokenPrefix}nest_access_token`, response.nest_access_token);
      localStorage.setItem(`${this.tokenPrefix}token-expired`, expDate.toString());
      //localStorage.setItem(TOKEN_NAME, response.ad_key);
      //localStorage.setItem(PRO_TOKEN_NAME, response.pro_key);
      this.cookieService.set(TOKEN_NAME, response.ad_key, expDate);
      this.cookieService.set(PRO_TOKEN_NAME, response.pro_key, expDate);
    } else {
      localStorage.removeItem(`${this.tokenPrefix}laravel_access_token`);
      localStorage.removeItem(`${this.tokenPrefix}nest_access_token`);
      localStorage.removeItem(`${this.tokenPrefix}token-expired`);
      localStorage.removeItem(`${this.tokenPrefix}currentUser`);
      this.cookieService.delete(TOKEN_NAME);
      localStorage.removeItem(TOKEN_NAME);
      this.cookieService.delete(PRO_TOKEN_NAME);
      localStorage.removeItem(PRO_TOKEN_NAME);
      ClientAuthService.logoutTimer(null);
      const lastPage = localStorage.getItem('clientHistory');
      if (lastPage !== null && lastPage !== '') {
        localStorage.setItem('clientHistory', lastPage);
      }
    }
  }

  static logout() {
    ClientAuthService.setToken(null);
  }

  login(user: User): Observable<ILaravelCredentials> {
    const body = {
      grant_type: 'password',
      client_id: this.environment.oauth_client_profile_id,
      client_secret: this.environment.oauth_client_profile_secret,
      username: user.email,
      password: user.password,
      scope: '*',
    };
    return this.http.post<ILaravelCredentials>(this.authUrl, body, this.httpOptions);
  }

  isAuthenticated(): boolean {
    const laravelToken = localStorage.getItem(`${this.tokenPrefix}laravel_access_token`);
    const nestToken = localStorage.getItem(`${this.tokenPrefix}nest_access_token`);
    const currentUser = localStorage.getItem(`${this.tokenPrefix}currentUser`);
    return !!laravelToken && !!nestToken && !!currentUser;
  }

  changePassword(body: {
    email: string; old_password: string; new_password: string;
  }): Observable<{ error: boolean; msg: string }> {
    return this.http.post<{
      error: boolean;
      msg: string
    }>(`${this.environment.baseUrl}api/v1/clients/passwd/change`, body);
  }

  resetPass(body: { email: string }) {
    return this.http.post<{
      error: boolean;
      msg: string
    }>(`${this.environment.baseUrl}api/v1/clients/passwd/reset`, body);
  }

  restorePass(body: { password: string; token: string }) {
    return this.http.post<{
      error: boolean;
      msg: string
    }>(`${this.environment.baseUrl}api/v1/clients/passwd/set`, body);
  }

  getUser(): Observable<BaseModel<Clients>> {
    return this.http.get<BaseModel<Clients>>(`${this.environment.apiAdminUrl}clients`);
  }

  createUser(body: CreateUserRequest) {
    return this.http.post<{ error: boolean; msg: string }>(`${this.environment.apiAdminUrl}clients`, body);
  }

  verifyEmail(body: { email: string, domain: string }) {
    return this.http.post<{ error: boolean; msg: string }>(`${this.environment.apiAdminUrl}verify-email`, body);
  }

  sendMailing(body: { email: string, link: string }) {
    return this.http.post<{ error: boolean; msg: string }>(`${this.environment.apiAdminUrl}send-mailing`, body);
  }
}

export interface CreateUserRequest {
  email: string;
  password?: string;
  receiver?: string;
}

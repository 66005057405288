import { PopupControllerInterface, PopupState } from '../../contracts';
import { BehaviorSubject, Subscription } from 'rxjs';

export enum InnerPopupState {
  None,
  InQueue,
  WaitingTriggers,
  NotBeShown,
  Displayed,
  Hidden,
}

export class InnerPopup {
  subscription: Subscription = new Subscription();
  state$ = new BehaviorSubject<InnerPopupState>(InnerPopupState.None);

  constructor(public controller: PopupControllerInterface, public queue: InnerPopup[]) {
    this.state$.next(this.mapPopupState(controller.state));

    this.subscription.add(
      controller.state$.subscribe(
        (state) => this.state$.next(this.mapPopupState(state))
      )
    );
  }

  get uid(): string {
    return this.controller.uid;
  }

  get queueIndex(): number {
    return this.queue.indexOf(this);
  }

  findPrevious(): null | InnerPopup {
    const pos = this.queue.indexOf(this);
    if (pos > 0 && pos <= this.queue.length) {
      return this.queue[pos - 1];
    }

    return null;
  }

  private mapPopupState(state: PopupState): InnerPopupState {
    switch (state) {
      case PopupState.Displayed:
        return InnerPopupState.Displayed;
      case PopupState.Hidden:
        return InnerPopupState.Hidden;
    }

    return this.state$.value;
  }
}

import {gql} from 'apollo-angular';

export const APPLY_DISCOUNT_FOR_PAID_SUBSCRIPTION = gql`
  mutation applyDiscountForPaidSubscription($key: String!, $id: Int!, $couponCode: String!, $newPlanId: Int!) {
    applyDiscountForPaidSubscription(key: $key, id: $id, couponCode: $couponCode, newPlanId: $newPlanId) {
      id
      email
      status
      retryAttempts
      startedAt
      nextProlongatedAt
      periodValue
      periodType
      amount
      totalAmount
      recurrentPaymentExternalId
      subscriptionPlanId
      deactivatedAt
      acquiringBank
      feedback
      ccsp {
        couponCodeId
        subscriptionPlanId
        applyCouponCode
        discountPercent
        isDiscounted
        promoPeriodValue
        promoPeriodType
        isOnlyForNew
        isRepetable
        showOriginalPrice
        newPrice
        couponCode {
          id
          code
          status
        }
      }
      createdAt
      updatedAt
      subscriptionPlan {
        id
        product
        name
        currency
        paymentSystemId
        periodType
        periodValue
      }
    }
  }
`;

export const CREATE_SETUP_INTENT_FOR_STRIPE = gql`
  mutation createSetupIntent($input: CreateSetupIntentInput!) {
    createSetupIntent(createSetupIntent: $input) {
      confirmationToken
      status
      error {
        success
        message
        }
    }
  }
`;

export const WRITE_SUBSCRIPTION_RETENTION_HISTORY = gql`
  mutation writeSubscriptionRetentionHistory($email: String!, $historyItem: WriteSubscriptionRetentionHistoryInput!) {
    writeSubscriptionRetentionHistory(email: $email, historyItem: $historyItem)
  }
`;

export const USER_SUBSCRIPTIONS = gql`
  query subscriptionsByAuth($email: String!, $productType: String!) {
    subscriptionsByAuth(email: $email, productType: $productType) {
      id
      email
      status
      retryAttempts
      startedAt
      nextProlongatedAt
      periodValue
      periodType
      amount
      totalAmount
      recurrentPaymentExternalId
      subscriptionPlanId
      deactivatedAt
      acquiringBank
      feedback
      ccspId
      ccsp {
        couponCodeId
        subscriptionPlanId
        applyCouponCode
        discountPercent
        newPrice
        promoPeriodType
        promoPeriodValue
        showOriginalPrice
        couponCode {
          id
          code
          status
        }
      }
      changes {
        id
        subscriptionId
        startDate
        endDate
        oldCcspId
        oldSubscriptionPlanId
      }
      createdAt
      updatedAt
      subscriptionPlan {
        id
        name
        description
        status
        periodValue
        periodType
        price
        createdAt
        updatedAt
        listId
        position
        slug
        currency
        paymentSystemId
        paymentSystemPriceId
        isExternalPayment
        externalLink
        customName
        confirmationMethod
        product
        comment
      }
    }
  }
`;

import { InnerPopup, InnerPopupState } from './inner-popup';
import { DisplayPolicyType, InteractPolicyType, PopupControllerInterface } from '../../contracts';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { merge, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

type PopupMap = Map<string, InnerPopup>;

@Injectable()
export class PopupQueueService {
  popupStates$: Observable<{uid: string; state: InnerPopupState}> = new Observable();
  popups: PopupMap = new Map();
  popupQueues: Map<string, InnerPopup[]> = new Map();

  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, any>) {}

  enqueue(popup: PopupControllerInterface, queueName: string = 'default'): boolean {
    if (this.popups.has(popup.uid)) {
      return false;
    }

    if (!this.popupQueues.has(queueName)) {
      this.popupQueues.set(queueName, []);
    }

    const queue = this.popupQueues.get(queueName);
    const innerPopup = new InnerPopup(popup, queue);

    queue.push(innerPopup);
    this.popups.set(popup.uid, innerPopup);
  }

  processAllQueues(): void {
    for (const queue of this.popupQueues.keys()) {
      this.processQueue(queue);
    }
  }

  async processQueue(queueName: string) {
    if (!isPlatformBrowser(this.platformId)) {
      console.warn('PopupQueueManager is not supported on server side');
      return;
    }

    if (!this.popupQueues.has(queueName)) {
      console.warn(`Popup queue with name ${queueName} is not found`);
      return;
    }

    const queue = [...this.popupQueues.get(queueName)];

    for (const popup of queue) {
      try {
        await this.handlePopup(popup);
      } catch (error) {
        console.error('Popup error:', error);
      }
    }
  }

  private async handlePopup(innerPopup: InnerPopup) {
    if (!(await innerPopup.controller.displayStrategy.canShown())) {
      innerPopup.state$.next(InnerPopupState.NotBeShown);
      this.removePopup(innerPopup);
      return;
    }

    // Этот банер стоит первым в очереди, его можно сразу отображать
    const previous = innerPopup.findPrevious();

    if (previous === null) {
      innerPopup.state$.next(InnerPopupState.InQueue);
      this.initPopup(innerPopup);
      return;
    } else if (innerPopup.controller.displayStrategy.displayPolicy === DisplayPolicyType.FirstOnly) {
      innerPopup.state$.next(InnerPopupState.NotBeShown);
      this.removePopup(innerPopup);
      return;
    }

    innerPopup.state$.next(InnerPopupState.InQueue);

    switch (previous.controller.displayStrategy.interactPolicy) {
      case InteractPolicyType.Waiting: {
        this.waitPreviousHidden(innerPopup);
        break;
      }

      case InteractPolicyType.Overlay:
        this.initPopup(innerPopup);
        break;

      case InteractPolicyType.Replace: {
        this.waitPreviousHidden(innerPopup);
        previous.controller.hide();
      }
    }
  }

  private initPopup(innerPopup: InnerPopup): void {
    if (innerPopup.state$.value !== InnerPopupState.InQueue) return;

    innerPopup.state$.next(InnerPopupState.WaitingTriggers);

    const state$ = innerPopup.state$.pipe(
      map((state) => ({uid: innerPopup.uid, state}))
    );
    this.popupStates$ = merge(this.popupStates$, state$);

    innerPopup.controller.displayStrategy
      .trigger()
      .then((result) => {
        if (result) {
          innerPopup.controller.show({ priority: innerPopup.queueIndex });
        } else {
          innerPopup.state$.next(InnerPopupState.NotBeShown);
          this.removePopup(innerPopup);
        }
      })
      .catch((error) => {
        console.error(error);

        innerPopup.state$.next(InnerPopupState.NotBeShown);
        this.removePopup(innerPopup);
      });
  }

  private removePopup(innerPopup: InnerPopup): void {
    const pos = innerPopup.queueIndex;
    if (pos >= 0) {
      innerPopup.queue.splice(pos, 1);
    }

    this.popups.delete(innerPopup.uid);
  }

  private waitPreviousHidden(innerPopup: InnerPopup): void {
    const prev = innerPopup.findPrevious();

    if (!prev) {
      this.initPopup(innerPopup);
      return;
    }

    innerPopup.subscription.add(
      this.popupStates$
        .pipe(filter((pps) =>
            pps.uid === prev.uid
            && [InnerPopupState.NotBeShown, InnerPopupState.Hidden].includes(pps.state)
          )
        )
        .subscribe(() => this.initPopup(innerPopup))
    );
  }
}
